import axios from 'axios';
import { BASE_IPFS_URL, MINT_API_URL } from 'helpers/utils';
import { AmaluEligibilityCheck, AmaluWithdrawalsCheck } from './queries/eligibilityQuery/amalu';
import { MerkleEligibilityCheck, MerkleWithdrawalsCheck } from './queries/eligibilityQuery/merkle';
import { qrCodeEligibility, qrCodeWithdrawals } from './queries/eligibilityQuery/qrcode';
import {
  TokenMappingEligibilityCheck,
  TokenMappingWithdrawalsCheck
} from './queries/eligibilityQuery/tokenmapping';
import { WhitelistEligibility, WhitelistWithdrawals } from './queries/eligibilityQuery/whitelist';

export const MINTING_STATUSES = {
  NONE: 'none',
  PROCESSING: 'processing',
  ERROR: 'error',
  DONE: 'done'
};

export const MINT_TYPES = {
  SELECTABLE: 'selectable',
  SEQUENTIAL: 'sequential'
};

export const TEXTLIST = {
  mobileHeader: '',
  symbol: '',
  successTitle: 'Palm OG POC',
  successMessage: `#%TOKEN_ID% now belongs to you`
};

export const highlightIndicators = {
  available: {
    background: '#4DFF64',
    shadow: '0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 17px 2px #4DFF64',
    status: '#00B318'
  },
  reserved: {
    background: '#FF9500',
    shadow: '0px 0px 10px 5px #FF9500',
    status: '#FF9500'
  },
  unavailable: {
    background: '#FF3333',
    shadow: '0px 0px 10px 5px #FF3333',
    status: '#FF3333'
  }
};

export const delayer = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const getExternalTokenImage = async (
  group,
  token,
  tokenUri,
  externalService,
  abortController
) => {
  const prefix = BASE_IPFS_URL;

  const internalServiceLink = `${process.env.NEXT_PUBLIC_MINT_API_URL}/minting/media/${group}/${token}`;

  try {
    if (!externalService) return internalServiceLink;

    if (!tokenUri.includes('http')) {
      const _uri = tokenUri.replace('ipfs://', '');

      const {
        data: { image, image_url, animation_url }
      } = await axios.get(`${prefix}${_uri}`, { signal: abortController.signal });

      const link = animation_url ?? image ?? image_url;

      return link?.replace('ipfs://', prefix);
    } else {
      const { data } = await axios.get(tokenUri, { signal: abortController.signal });
      const { image, image_url, animation_url } = data;

      return animation_url ?? image ?? image_url ?? tokenUri;
    }
  } catch (error) {
    if (!tokenUri.includes('http')) {
      const uri = `${prefix}${tokenUri}`;
      const {
        data: { image, image_url, animation_url }
      } = await axios.get(uri, { signal: abortController.signal });
      const link = animation_url ?? image ?? image_url;
      return link?.replace('ipfs://', prefix) ?? image_url ?? internalServiceLink;
    }
    if (!abortController.signal.aborted) {
      console.log(error);
    }
  }
};

export const reserveToken = async ({ value: tokenId }, address, tx, groupKey) => {
  return await axios.post(`${MINT_API_URL}/minting/${groupKey}/reserve/${tokenId}`, tx);
};

export const getTokensRange = (series) => {
  let _min;
  let _max;
  if (series.length > 1) {
    const { min, max } = series.reduce(
      ({ min, max }, curr) => {
        const minimum = min > curr.range.start ? curr.range.start : min;
        const maximum = max < curr.range.end ? curr.range.end : max;
        return { min: minimum, max: maximum };
      },
      { min: 1, max: 0 }
    );
    _min = min;
    _max = max;
  } else {
    _min = series[0].range?.start;
    _max = series[0].range?.end;
  }
  return { start: _min, end: _max };
};

export const getMediaMimetype = async (url) => {
  if (!url) return 'image';
  try {
    const { headers, data } = await axios.get(url);
    const mimetype = headers['content-type'];
    return {
      mimetype: mimetype.includes('image') ? 'image' : 'video',
      placeholderVideoId: data.placeholderVideoId
    };
  } catch (err) {
    console.log(err);
    return { mimetype: 'image' };
  }
};

export const keyToQueryEligibilityMap = {
  amalu: AmaluEligibilityCheck,
  merkle: MerkleEligibilityCheck,
  tokenmapping: TokenMappingEligibilityCheck,
  whitelist: WhitelistEligibility,
  qrcode: qrCodeEligibility
};

export const keyToQueryWithdrawalsMap = {
  amalu: AmaluWithdrawalsCheck,
  merkle: MerkleWithdrawalsCheck,
  tokenmapping: TokenMappingWithdrawalsCheck,
  whitelist: WhitelistWithdrawals,
  qrcode: qrCodeWithdrawals
};
