import axios from 'axios';
import VotingIdentity from 'contracts/VotingIdentity2.json';
import { ethInstance, ipfsGet } from 'evm-chain-scripts';
import { API_URL } from 'helpers/utils';

export function getNftContracts(nftAddresses) {
  let contracts = [];

  const abi = [
    {
      constant: true,
      inputs: [
        {
          internalType: 'address',
          name: 'account',
          type: 'address'
        }
      ],
      name: 'balanceOf',
      outputs: [
        {
          internalType: 'uint256',
          name: '',
          type: 'uint256'
        }
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    }
  ];

  if (nftAddresses) {
    for (const { chainId, address } of nftAddresses) {
      contracts.push(ethInstance.getReadContractByAddress({ abi }, address, chainId));
    }
  }

  return contracts;
}

export async function isNftMember(nftAddresses, walletAddress) {
  try {
    const contracts = await Promise.all(getNftContracts(nftAddresses));
    const balances = await Promise.all(
      contracts.map(async (contract) => {
        const balance = await contract.balanceOf(walletAddress);
        const settings = nftAddresses.find((nftItem) => nftItem.address === contract.address);

        return {
          balance,
          permission: balance >= settings.minNftAmount
        };
      })
    );
    return balances.some((contract) => contract.permission);
  } catch (err) {
    console.log('Error while checking nft members', err);
  }
}

export function getContractsByNFTAddresses(nftAddresses) {
  let contracts = [];

  if (nftAddresses) {
    for (const key in nftAddresses) {
      const addresses = nftAddresses[key];
      for (const nftAddress of addresses) {
        contracts.push(ethInstance.getReadContractByAddress(VotingIdentity, nftAddress, key));
      }
    }
  }

  return contracts;
}

export async function getCombinedVoterIds(address, nftAddresses, settings) {
  let contracts = getContractsByNFTAddresses(nftAddresses);

  const contractsResult = await Promise.all(contracts);
  const groupedNFTs = {};

  for (const contract of contractsResult) {
    const [symbol, balance] = await Promise.all([contract.symbol(), contract.balanceOf(address)]);
    const nftCounts = parseInt(balance);
    if (nftCounts == 0) continue;

    const isOldContract =
      settings.find((setting) => setting.address === contract.address).isOldImplementation ?? false;

    if (isOldContract) {
      const {
        data: { identities }
      } = await axios.get(`${API_URL}/api/identities/sim-multi/${contract.address}/${address}`);

      if (identities.length)
        groupedNFTs[contract.address] = identities.map((id) => {
          return { nftId: id, symbol: symbol };
        });
    } else {
      let nftIdsPromisses = [];

      for (let i = 0; i < nftCounts; i++) {
        nftIdsPromisses.push(contract.tokenOfOwnerByIndex(address, i));
      }

      const nftIds = await Promise.all(nftIdsPromisses);

      groupedNFTs[contract.address] = nftIds.map((id) => {
        return { nftId: parseInt(id), symbol: symbol };
      });
    }
  }

  return groupedNFTs;
}

export async function getAllIdentitiesForAccount(address, network, collectionName) {
  const {
    data: { identities }
  } = await axios.get(`${API_URL}/api/identities/${network}/${collectionName}/${address}`);
  return identities;
}

export async function getFromBackend(ipfsHash) {
  const url = `${API_URL}/api/proposals/${ipfsHash}`;
  const response = await axios.get(url);
  return response.data;
}

export function shortenWalletAddress(address) {
  return address ? `${address.substr(0, 6)}...${address.substr(-4)}` : '';
}

export async function getNftMetadata(uri) {
  if (uri) {
    try {
      if (!uri) return null;
      let metadata;
      if (uri.startsWith('ipfs://')) metadata = await ipfsGet(uri.replace('ipfs://', ''));
      else if (uri.startsWith('Q')) metadata = await ipfsGet(uri);
      else {
        metadata = await fetch(uri).then((res) => res.json());
      }
      return metadata;
    } catch (e) {
      return null;
    }
  }
}

export default { getNftMetadata };
