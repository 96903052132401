export const IssueTabs = {
  my: 'my issues',
  community: 'community issues'
};

export const VoteTabs = {
  myVote: 'my vote',
  opinions: 'opinions',
  results: 'results'
};

export const QuestionnaireData = [
  {
    question: 'Which area of London are you from?',
    answers: ['North', 'South', 'East', 'West']
  },
  {
    question: 'What is your age range?',
    answers: ['Below 18', '18 - 24', '25 - 34', '35 - 44', '45 - 54', '55 - 64', 'Above 65']
  },
  {
    question: 'What gender do you identify as?',
    answers: ['Male', 'Female', 'Trans-gender', 'Non-binary', 'Prefer not to answer']
  },
  {
    question: 'What is your marital status?',
    answers: [
      'Married',
      'Divorced',
      'Cohabiting',
      'Civil partnership',
      'Single',
      'Prefer not to say'
    ]
  },
  {
    question:
      'What is your ethnic background? Choose from one option that best describes your ethnic group or background.',
    answers: [
      'English, Welsh, Scottish, Northern Irish or British',
      'Irish',
      'Gypsy or Irish Traveller',
      'Roma',
      'Any other White background',
      'Indian',
      'Pakistani',
      'Bangladeshi',
      'Chinese',
      'Any other Asian or mixed Asian background',
      'Black Caribbean',
      'Black African',
      'Black British',
      'Any other Black or mixed Black background',
      'Mixed White and Black Caribbean',
      'Mixed White and Black African',
      'Mixed White and Asian',
      'Arab',
      'Jewish',
      'Any other Mixed or multiple ethnic background',
      'Any other ethnic group',
      'Prefer not to say'
    ]
  },
  {
    question: 'What is your highest level of education?.',
    answers: [
      'Doctorate degree',
      'Master`s degree',
      'Bachelor`s degree',
      'Associate degree',
      'Trade/technical/vocational training',
      'High school/college graduate, diploma or equivalent',
      'Other',
      'Prefer not to say'
    ]
  },
  {
    question: 'What is your current employment status?',
    answers: [
      'Full-time employment',
      'Self-employed',
      'Part-time employment',
      'Full time freelancing',
      'Unemployed (looking for work)',
      'Unemployed (not looking for work)',
      'Student',
      'Unable to work',
      'Retired',
      'Prefer not to say'
    ]
  },
  {
    question: 'How would you describe your personal political views?',
    answers: [
      'Very liberal',
      'Somewhat liberal',
      'Somewhat conservative',
      'Very conservative',
      'Neutral or no political views',
      'Prefer not to say'
    ]
  },
  {
    question: 'How would you best describe your current living situation?',
    answers: [
      'Homeowner',
      'Tenant (renting)',
      'Living with friends or family',
      'Student dorms',
      'Other',
      'Prefer not to say'
    ]
  }
];
