import { useQuery } from '@tanstack/react-query';
import {
  fetchStrategies,
  generateChoices,
  getDemographicActive,
  getMultiChainPowerFromBackend,
  getPowerFromBackend,
  getSpaceData,
  getUserRank
} from 'helpers/utils';
import {
  calcVotesAndOpinions,
  calculateResults,
  fetchNftImage,
  fetchProposalVotesData,
  getIdentities,
  getIdentitiesDropdownOptions,
  getMultiChainIdentitiesDropdownOptions,
  getMultiChainVoterNftIdentities,
  getMyInfluenceProps,
  getProposalData,
  getVoteComponentProps,
  sortVotesList
} from './proposal';
import { getTaskData } from './tasks';

const QUERIES = {
  SCORE: 'score',
  MULTI_CHAIN_SCORE: 'multi-chain-score',
  MULTI_CHAIN_IDENTITIES: 'MULTI_CHAIN_IDENTITIES',
  MULTI_CHAIN_IDENTITIES_DROPDOWN: 'MULTI_CHAIN_IDENTITIES_DROPDOWN',
  IDENTITIES: 'IDENTITIES',
  NFTS: 'NFT_IDENTITIES',
  INFLUENCE_PROPS: 'INFLUENCE_PROPS',
  VOTESOPINIONS: 'VOTES_OPINIONS',
  IDENTITIESDROPDOWN: 'IDENTITIES_DROPDOWN',
  NFTIMAGE: 'NFT_IMAGE',
  VOTEPROPS: 'VOTE_PROPS',
  TASKDATA: 'TASK_DATA',
  GET_PROPOSAL_DATA: 'PROPOSAL_DATA',
  FETCHED_VOTES_DATA: 'PROPOSAL_VOTES_DATA',
  SORTED_VOTES_LIST: 'SORTED_VOTES_LIST',
  PROPOSAL_RESULTS: 'PROPOSAL_RESULTS',
  RANK: 'RANK',
  SPACE_DATA: 'SPACE_DATA'
};

export const useScoreQuery = ({ address, currentNetwork, proposalHash, strategy, identity }) =>
  useQuery({
    queryKey: [QUERIES.SCORE, proposalHash, address, currentNetwork, identity],
    queryFn: async () => getPowerFromBackend({ address, currentNetwork, proposalHash, identity }),

    enabled: !!(
      address &&
      currentNetwork &&
      proposalHash &&
      (strategy?.params.type !== 'nft' || identity)
    )
  });

export const useMultiChainScoreQuery = ({ address, proposalHash }) =>
  useQuery({
    queryKey: [QUERIES.MULTI_CHAIN_SCORE, address, proposalHash],
    queryFn: async () => getMultiChainPowerFromBackend({ address, proposalHash }),

    enabled: !!(address && proposalHash)
  });

export const useUserRank = ({ address, proposalHash, currentNetwork, strategyKey }) =>
  useQuery({
    queryKey: [QUERIES.RANK, address, proposalHash],
    queryFn: async () => getUserRank({ address, proposalHash, currentNetwork }),

    enabled: !!(
      address &&
      proposalHash &&
      currentNetwork &&
      ['deposit-rank', 'deposit-balance'].includes(strategyKey)
    )
  });

export const useGetUserMultiChainIdentities = (votes, strategy, address, acceptableChains) =>
  useQuery({
    queryKey: [QUERIES.MULTI_CHAIN_IDENTITIES, votes, strategy, address, acceptableChains],
    queryFn: async () =>
      getMultiChainVoterNftIdentities(votes, strategy, address, acceptableChains),

    enabled: !!(address || (votes && strategy)),
    refetchOnWindowFocus: false
  });

export const useGetUserIdentities = (votes, strategy, isOpenVote, address, currentNetwork) =>
  useQuery({
    queryKey: [QUERIES.IDENTITIES, votes, strategy, isOpenVote, address, currentNetwork],
    queryFn: async () => getIdentities(votes, strategy, isOpenVote, address, currentNetwork),

    enabled: !!(address && votes && currentNetwork && strategy),
    refetchOnWindowFocus: false
  });

export const useGetMyInfluenceProps = (
  tab,
  isProposalVisible,
  address,
  totalResults,
  strategy,
  choices
) =>
  useQuery({
    queryKey: [QUERIES.INFLUENCE_PROPS, tab, isProposalVisible, address, totalResults, strategy],
    queryFn: () => getMyInfluenceProps(tab, isProposalVisible, totalResults, strategy),

    enabled: !!(tab && totalResults.voteCredits >= 0 && strategy && choices)
  });

export const useCalcVotesAndOpinions = (votes, choices, id, isBinary) =>
  useQuery({
    queryKey: [QUERIES.VOTESOPINIONS, Object.keys(votes), choices, id],
    queryFn: async () => calcVotesAndOpinions(votes, choices, isBinary),

    enabled: !!(votes && choices, id)
  });

export const useGetMultiChainIdentitiesDropdownOptions = (identities, className) =>
  useQuery({
    queryKey: [QUERIES.MULTI_CHAIN_IDENTITIES_DROPDOWN, identities, className],
    queryFn: async () => getMultiChainIdentitiesDropdownOptions(identities, className),

    enabled: !!identities
  });

export const useGetIdentitiesDropdownOptions = (
  identities,
  strategy,
  shortLabel,
  className,
  address
) =>
  useQuery({
    queryKey: [
      QUERIES.IDENTITIESDROPDOWN,
      Object.keys(identities),
      strategy,
      shortLabel,
      className,
      address
    ],
    queryFn: async () => getIdentitiesDropdownOptions(identities, strategy, shortLabel, className),

    enabled: !!(address && strategy)
  });

export const useFetchNftImage = (tokenURI) =>
  useQuery({
    queryKey: [QUERIES.NFTIMAGE, tokenURI],
    queryFn: async () => fetchNftImage(tokenURI),

    enabled: !!tokenURI,
    refetchOnWindowFocus: false
  });

export const useGetVoteComponentProps = (
  address,
  strategy,
  setUnsortedChoices,
  setTokensLeft,
  tab,
  choices
) =>
  useQuery({
    queryKey: [QUERIES.VOTEPROPS, address, strategy, tab],
    queryFn: () =>
      getVoteComponentProps(address, strategy, setUnsortedChoices, setTokensLeft, choices),

    enabled: !!(address && strategy && choices.length)
  });

export const useLoadChoicesData = (results, payload, tab) =>
  useQuery({
    queryKey: ['loadData', payload, results, tab],
    queryFn: () => generateChoices(payload?.choices, results),

    enabled: !!(payload && tab),
    refetchOnWindowFocus: false
  });

export const useGetTaskData = (taskHash, spaceKey, address) =>
  useQuery({
    queryKey: [QUERIES.TASKDATA, taskHash, spaceKey, address],
    queryFn: async () => getTaskData(taskHash, spaceKey, address),

    enabled: !!(taskHash && spaceKey && address),
    refetchOnWindowFocus: false,
    initialData: {
      submissionsData: [],
      mySubmissionsData: [],
      contributorsData: [],
      identitiesForRealmByUser: {},
      isEligible: false,
      questionData: {}
    }
  });

export const useFetchProposalVotesData = (
  proposal,
  address,
  spaceKey,
  id,
  signWindowOpenRef,
  currentNetwork
) =>
  useQuery({
    queryKey: [
      QUERIES.FETCHED_VOTES_DATA,
      spaceKey,
      address,
      id,
      currentNetwork,
      proposal && Object.keys(proposal)
    ],
    queryFn: async () =>
      fetchProposalVotesData(proposal, address, spaceKey, id, signWindowOpenRef, currentNetwork),

    enabled: !!(spaceKey && id && currentNetwork && proposal),
    refetchOnWindowFocus: false,
    initialData: {
      votes: {},
      isProposalVisible: false,
      isCombinedChainsVoting: false
    }
  });

export const useSortVotesList = (votes) =>
  useQuery({
    queryKey: [QUERIES.SORTED_VOTES_LIST, Object.keys(votes)],
    queryFn: async () => sortVotesList(votes),

    enabled: !!(votes && Object.keys(votes)),
    refetchOnWindowFocus: false
  });

export const useCalculateResults = (
  votes,
  currentNetwork,
  proposal,
  address,
  isCombinedChainsVoting = false
) =>
  useQuery({
    queryKey: [
      QUERIES.PROPOSAL_RESULTS,
      Object.keys(votes),
      currentNetwork,
      proposal,
      address,
      isCombinedChainsVoting
    ],
    queryFn: async () =>
      calculateResults(votes, currentNetwork, proposal, address, isCombinedChainsVoting),

    enabled: !!(votes && currentNetwork && proposal && proposal?.strategy?.key),
    refetchOnWindowFocus: false,
    initialData: {
      influenceResult: [],
      myInfluenceResult: [],
      myInfluenceResultPerIdentity: []
    }
  });

export const useGetProposalData = (id) =>
  useQuery({
    queryKey: [QUERIES.GET_PROPOSAL_DATA, id],
    queryFn: async () => getProposalData(id),
    enabled: !!id,
    refetchOnWindowFocus: false
  });

export const useGetStrategies = () =>
  useQuery({
    queryKey: ['strategies'],
    queryFn: async () => fetchStrategies(),
    enabled: true,
    refetchOnWindowFocus: false
  });

export const useGetSpaceData = (spaceName) =>
  useQuery({
    queryKey: [QUERIES.SPACE_DATA, spaceName],
    queryFn: async () => getSpaceData(spaceName),
    enabled: !!spaceName,
    refetchOnWindowFocus: false
  });

export const useGetDemographic = (spaceName, walletAddress, identity) =>
  useQuery({
    queryKey: ['demographic', spaceName, walletAddress, identity],
    queryFn: async () => getDemographicActive(spaceName, walletAddress, identity),
    enabled: !!(spaceName && walletAddress && identity),
    refetchOnWindowFocus: true
  });
