import axios from 'axios';
import { MINTING_STATUSES } from 'components/Activities/ClaimVoice/helpers';
import { isNftMember } from 'helpers/influence';
import { useGetSpaceData, useGetStrategies } from 'helpers/queries';
import { API_URL } from 'helpers/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { createGlobalState } from 'react-hooks-global-state';
import { useAccount, useAccountEffect } from 'wagmi';

const initialState = {
  currentNetwork: 1,
  address: '',
  space: {},
  strategies: [],
  isMember: false,
  members: [],
  isGlobalAdmin: false,
  proposalListFilters: {
    status: [], // empty Array retrieves all statuseses
    sign: [], // empty Array retrieves all tags
    itemsPerPage: '3'
  },
  taskListFilters: {
    status: [],
    sign: [], // empty Array retrieves all tags
    itemsPerPage: '3'
  },
  proposalStatus: [],
  taskStatus: [],
  taskListActivePage: 1,
  taskListSorting: { column: null, direction: null },
  proposalListActivePage: 1,
  proposalListSorting: { column: null, direction: null },
  walletRef: null,
  showChainsDropdown: false,
  supportedChains: [],
  identity: '',
  identities: [],
  mintingStatus: MINTING_STATUSES.NONE
};
const initialProposalState = {
  initData: {
    key: '',
    id: '',
    totalScore: 0,
    proposal: {},
    votes: {},
    results: {},
    selectedChoice: 0,
    isProposalVisible: true,
    payload: {},
    hasMyVotesVoted: false,
    isDraft: false,
    hackathonView: false
  },
  choices: [],
  opinions: [],
  voteDialogOpen: false,
  loading: true,
  myChoices: [],
  myUnsortedChoices: [],
  unsortedChoices: [],
  identity: '',
  identities: [],
  totalI: 0,
  totalVotes: 0,
  totalResults: {
    votes: 0,
    voteCredits: 0,
    voter: 0
  },
  isPageLoading: true,
  opinionChoices: []
};
export const {
  getGlobalState: getProposalState,
  useGlobalState: useProposalState,
  setGlobalState: setProposalState
} = createGlobalState(initialProposalState);

export const { useGlobalStateProvider, useGlobalState, setGlobalState } =
  createGlobalState(initialState);

export const proposalStateCleanup = () => {
  for (const key of Object.keys(initialProposalState))
    setProposalState(key, initialProposalState[key]);

  setGlobalState('showChainsDropdown', false);
};

export const GlobalStateProvider = ({ children }) => {
  const router = useRouter();
  const { address, isConnected } = useAccount();

  const { spaceKey } = router.query;
  const [members, setMembers] = useGlobalState('members');
  const [, setWalletAddress] = useGlobalState('address');
  const [space, setSpace] = useGlobalState('space');
  const { data: strategies } = useGetStrategies();
  let key = spaceKey && spaceKey.includes('-') ? spaceKey.split('-')[1] : spaceKey;

  const { data: spaceData } = useGetSpaceData(key);

  useAccountEffect({
    onConnect(data) {
      console.log('Connected!', data.address);
    },
    onDisconnect() {
      console.log('Disconnected!!');
      onDisconnect();
    }
  });

  useEffect(() => {
    if (!strategies?.length) return;
    setGlobalState('strategies', strategies);
  }, [strategies]);

  useEffect(() => {
    const space = spaceData?.data;
    if (!space) return;
    setMembers(space.members.map((member) => member.toLowerCase()));
    setSpace(space);
  }, [spaceData?.data]);

  const checkIsMember = async () => {
    if (address) {
      const isMember =
        space.restrictionType === 'nft'
          ? await isNftMember(space.nftAddresses, address)
          : members.includes(address.toLowerCase());

      setGlobalState('isMember', isMember);
    } else {
      setGlobalState('isMember', false);
    }
  };

  /**
   * check if user is Admin
   *
   * @returns true or false if found on admin list stored on backend
   */
  const getIsAdmin = async () => {
    try {
      if (!address) {
        setGlobalState('isGlobalAdmin', false);
        return;
      }
      const {
        data: { isAdmin }
      } = await axios.get(`${API_URL}/api/is-admin/${address}`);
      setGlobalState('isGlobalAdmin', isAdmin);
    } catch (e) {
      console.error(`Error while getting admin list: ${e}`);
    }
  };

  // const checkNetwork = async (whichTry = 0) => {
  //   try {
  //     if (whichTry > 10) return;
  //     const currentNetwork = ethInstance.getChainId();
  //     if (!currentNetwork) {
  //       return setTimeout(async () => await checkNetwork(whichTry + 1), 300);
  //     }
  //     setGlobalState('currentNetwork', currentNetwork);
  //   } catch (err) {
  //     console.log('Check network error:', err);
  //     return err;
  //   }
  // };

  const onDisconnect = async () => {
    const cookies = document.cookie.split(';');
    const sessionCookies = cookies.filter((cookie) => cookie.includes('walletAddress'));

    if (sessionCookies.length) {
      const cookiesStore = new Cookies();
      for (const cookie of sessionCookies) {
        cookiesStore.remove(cookie);
      }
    }

    checkIsMember();
  };

  useEffect(() => {
    setWalletAddress(address ? address.toLowerCase() : '');
    checkIsMember();
    (async () => {
      await getIsAdmin();
    })();
  }, [members, address, isConnected]);

  useEffect(() => {
    if (spaceKey) {
      setGlobalState('proposalListFilters', initialState.proposalListFilters);
      setGlobalState('proposalListActivePage', initialState.proposalListActivePage);
      setGlobalState('proposalListSorting', initialState.proposalListSorting);
      setGlobalState('taskListFilters', initialState.taskListFilters);
      setGlobalState('taskListActivePage', initialState.taskListActivePage);
      setGlobalState('taskListSorting', initialState.taskListSorting);
    }
  }, [spaceKey]);

  useEffect(() => {
    if (!spaceKey && !location.pathname.includes('add-new-realm')) {
      // reset single space when user is on the home page
      setSpace({});
    }
  }, [spaceKey, location.pathname]);

  return children;
};

export default GlobalStateProvider;
