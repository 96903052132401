import axios from 'axios';
import networks from 'configs/networks.json';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const getFormattedTime = (date) => dayjs.utc(date).format('DD/MM/YY HH:mm UTC');

export function jsonParse(input, fallback) {
  if (typeof input !== 'string') {
    return fallback || {};
  }
  try {
    return JSON.parse(input);
  } catch (err) {
    return fallback || {};
  }
}

export function formatProposal(proposal) {
  proposal.msg = jsonParse(proposal.msg, proposal.msg);
  return proposal;
}

export function shorten(str, key) {
  if (!str) return str;
  let limit;
  if (typeof key === 'number') limit = key;
  if (key === 'symbol') limit = 6;
  if (key === 'name') limit = 64;
  if (key === 'choice') limit = 12;
  if (limit) return str.length > limit ? `${str.slice(0, limit).trim()}...` : str;
  return `${str.slice(0, 6)}...${str.slice(str.length - 4)}`;
}

export function generateRandomBackground(id) {
  let hash = 0;
  if (id === 0) return hash;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  var color = '#';
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 255;
    color += ('00' + value.toString(16)).substr(-2);
  }

  return color;
}

export function explorer(network, str, type = 'address') {
  return `${networks[network]?.explorer}/${type}/${str}`;
}

export function shuffle(array) {
  var currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export async function getMultiChainPowerFromBackend({ address, proposalHash }) {
  try {
    const {
      data: { totalScore }
    } = await axios.get(`${API_URL}api/proposals/${proposalHash}/vote-power/${address}`);
    return totalScore;
  } catch (error) {
    console.log('GET_MULTI_POWER_FAILURE', error);
  }
}

export async function getPowerFromBackend({ address, currentNetwork, proposalHash, identity }) {
  try {
    const {
      data: { totalScore }
    } = await axios.get(
      `${API_URL}/api/proposals/${proposalHash}/vote-power/${address}?identity=${identity}&network=${currentNetwork}`
    );
    return totalScore;
  } catch (e) {
    console.log('GET_POWER_FAILURE', e);
  }
}

export async function getUserRank({ address, currentNetwork, proposalHash }) {
  try {
    const {
      data: { userPosition, maxLevel }
    } = await axios.get(
      `${API_URL}/api/proposals/${proposalHash}/rank/${address}?network=${currentNetwork}`
    );
    return { userPosition, maxLevel };
  } catch (e) {
    console.log('GET_USER_RANK', e);
    return { userPosition: 0, maxLevel: 0 };
  }
}

export const generateChoices = (choices, results) => {
  const parsedChoices = choices.map((choice, i) => {
    const { submission } = choice;
    const choiceElem = submission
      ? {
          _id: choice._id,
          id: choice.id,
          value: submission.title,
          description: submission.body,
          richMedia: submission.richMedia
        }
      : choice;
    return {
      i,
      choice: choiceElem,
      progress: results?.length > 0 ? Math.round(results[i]) : 0,
      value: 0,
      opinion: choice.opinion
    };
  });
  return [shuffle(parsedChoices), [...parsedChoices].sort((a, b) => b.progress - a.progress)];
};

export const getParsedUserId = (userId) => {
  if (!userId) return {};

  const splitId = userId.split('-');
  const [chainId, contractAddress, nftName, nftId, os1155] = splitId;

  if (splitId.length > 1) {
    return {
      chainId,
      contractAddress,
      nftName,
      nftId,
      os1155
    };
  } else {
    return { contractAddress: userId };
  }
};

export const validateAcceptableChains = (acceptableChains, contractsObject) => {
  return Object.keys(contractsObject)
    .filter((chainId) => acceptableChains.includes(parseInt(chainId)))
    .reduce((chainData, chainId) => {
      chainData[chainId] = contractsObject[chainId];
      return chainData;
    }, {});
};

export const fetchStrategies = async () => {
  try {
    const { data: strategies } = await axios.get(`${API_URL}/api/strategies`);
    return strategies;
  } catch (err) {
    console.log('Failed to fetch strategies.', err);
  }
};

export const getSpaceData = async (spaceName) => {
  try {
    const space = await axios.get(`${API_URL}/api/spaces/${spaceName}`);
    return space;
  } catch (err) {
    console.log('Failed to fetch space.', err);
  }
};

export const getDemographicActive = async (spaceName, walletAddress, identity) => {
  try {
    const demographic = await axios.get(
      `${API_URL}/api/demographics/${spaceName}/${walletAddress}/${identity}`
    );

    return demographic;
  } catch (err) {
    console.log('Failed to fetch space.', err);
  }
};

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const MINT_API_URL = process.env.NEXT_PUBLIC_MINT_API_URL;
export const GRAPHQL_ENDPOINT = process.env.NEXT_PUBLIC_GRAPH_QL_ENDPOINT;
export const BASE_IPFS_URL =
  process.env.NEXT_PUBLIC_BASE_IPFS_URL || 'https://factorydao.infura-ipfs.io/ipfs/';
