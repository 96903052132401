import { ConnectButton } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { watchAccount } from '@wagmi/core';
import axios from 'axios';
import ConnectorsModal from 'components/Activities/ConnectorsModal/ConnectorsModal';
import { toChecksumAddress } from 'evm-chain-scripts';
import { useGlobalState } from 'globalStateStore';
import { API_URL } from 'helpers/utils';
import { wagmiConfig } from 'helpers/wagmiConfig';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { SiweMessage } from 'siwe';
import { useAccount, useSignMessage } from 'wagmi';
import styles from './Siwe.module.scss';

const SignInButton = ({ walletElement }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [address, setWalletAddress] = useGlobalState('address');
  const [chainId] = useGlobalState('currentNetwork');
  const [cookies, setCookie] = useCookies();
  const [showConnectorsDialog, setShowConnectorsDialog] = useState(false);

  const { isConnected } = useAccount();
  const { signMessageAsync } = useSignMessage();

  const signIn = async () => {
    try {
      if (!address || !chainId || !isConnected) return;

      const response = await axios.get(`${API_URL}/api/siwe/nonce?address=${address}`);

      const { tempToken, nonce } = response.data;

      const expDate = new Date();

      expDate.setHours(expDate.getHours() + 24);

      const message = new SiweMessage({
        domain: window.location.host,
        address: toChecksumAddress(address),
        statement: 'Sign in with Ethereum to the app.',
        uri: window.location.origin,
        version: '1',
        chainId,
        nonce: nonce,
        expirationTime: expDate.toISOString()
      });

      const preparedMessage = message.prepareMessage();

      const signature = await signMessageAsync({ message: preparedMessage });

      const body = {
        message,
        signature,
        tempToken,
        address: address
      };

      setCookie(`walletAddress`, address);
      setCookie(`session_${address}`, tempToken);

      const verifyRes = await axios.post(`${API_URL}/api/siwe/verify`, body);
      const token = verifyRes?.data?.token;

      if (verifyRes.status === 200 && token) {
        setCookie(`session_${address}`, token, {
          expires: expDate
        });
        setIsLoading(false);
        setWalletAddress(address.toLowerCase());
      }
    } catch (error) {
      console.log(error);
      await signIn();
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        if (isConnected && address) {
          const session = cookies?.[`session_${address}`];

          if (!session) {
            await signIn();
          }

          setCookie(`walletAddress`, address);

          const res = await axios.get(`${API_URL}/api/siwe/check?address=${address}`);

          if (res.status !== 200) {
            await signIn();
          } else {
            setWalletAddress(address.toLowerCase());
          }
        }
      } catch (_error) {
        console.error(_error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [isConnected, address]);

  useEffect(() => {
    const unwatch = watchAccount(wagmiConfig, {
      onChange(account) {
        console.log('Account changed!', account);
      }
    });
    return () => unwatch();
  }, []);

  return (
    <div className={styles.container}>
      <ConnectorsModal
        showConnectorsModalDialog={showConnectorsDialog}
        setConnectorsModalDialog={setShowConnectorsDialog}
      />

      {isLoading ? (
        'Loading...'
      ) : (
        <ConnectButton.Custom>
          {({
            account,
            chain,
            openAccountModal,
            openChainModal,
            authenticationStatus,
            mounted
          }) => {
            // Note: If your app doesn't use authentication, you
            // can remove all 'authenticationStatus' checks
            const ready = mounted && authenticationStatus !== 'loading';
            const connected =
              ready &&
              account &&
              chain &&
              (!authenticationStatus || authenticationStatus === 'authenticated');

            return (
              <div
                className={styles.buttonContainer}
                {...(!ready && {
                  'aria-hidden': true,
                  style: {
                    opacity: 0,
                    pointerEvents: 'none',
                    userSelect: 'none'
                  }
                })}>
                {(() => {
                  if (!connected) {
                    return (
                      <>
                        <button
                          id="connect-button"
                          onClick={() => setShowConnectorsDialog(true)}
                          type="button"
                          style={{ background: 'none', border: 'none', width: '100%' }}>
                          {walletElement}
                        </button>
                      </>
                    );
                  }

                  if (chain.unsupported) {
                    return (
                      <button
                        onClick={openChainModal}
                        type="button"
                        style={{ background: 'none', border: 'none', width: '100%' }}>
                        {walletElement}
                      </button>
                    );
                  }

                  return (
                    <button
                      onClick={openAccountModal}
                      type="button"
                      style={{ background: 'none', border: 'none', width: '100%' }}>
                      {walletElement}
                    </button>
                  );
                })()}
              </div>
            );
          }}
        </ConnectButton.Custom>
      )}
    </div>
  );
};

export default SignInButton;
