import classnames from 'classnames';
import localforage from 'localforage';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Button from '../Button';
import styles from './DisclaimerModal.module.scss';

const DisclaimerModal = () => {
  const [showDisclaimerDialog, setDisclaimerDialog] = useState(false);

  useEffect(() => {
    (async () => {
      const visited = await localforage.getItem('disclaimerAccepted');

      if (!visited) {
        setTimeout(() => setDisclaimerDialog(true), 500);
      }
    })();
  }, []);

  async function handleAcceptTerms() {
    setDisclaimerDialog(false);

    await localforage.setItem('disclaimerAccepted', true);
  }

  return showDisclaimerDialog ? (
    <>
      <div className={styles.backdrop} />
      <div className={classnames(styles.disclaimerForm, styles.modal)}>
        <div className={styles.headerText}>Disclaimer</div>
        <div className={styles.modalBody}>
          <div>Welcome to the London Voice App!</div>
          <div>Before you dive in, please take a moment to review our terms:</div>
          <div className={styles.points}>
            <ol>
              <li>
                Data Usage: Your participation and the data you provide will be used to enhance
                community decision-making processes. By continuing, you agree to our{' '}
                <Link href="https://ldnvoice.factorylabs.org/privacy-policy-app" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and how we handle your information.
              </li>
              <li>
                Content Sharing: Contributions made within the app may be used in discussions,
                reports, and promotions related to London Voice activities. No personal identifiable
                information will be disclosed in these activities. All efforts will be made to
                ensure privacy and respect for all users.
              </li>
              <li>
                Recording Consent: Portions of our events and activities may be recorded for
                archival and promotional purposes. Direct recordings of app interactions for
                promotional purposes will only be made with explicit user consent.
              </li>
              <li>
                Community Standards: We are committed to maintaining a respectful and inclusive
                environment. Please adhere to our{' '}
                <Link href="https://ldnvoice.factorylabs.org/community-guidelines" target="_blank">
                  community guidelines
                </Link>{' '}
                at all times.
              </li>
            </ol>
          </div>
          <div className={styles.summary}>
            By clicking `Agree` you acknowledge that you understand and consent to these terms. If
            you do not agree, you may discontinue use of the app.
          </div>
        </div>
        <div className={styles.paragraph}>
          <Button onClick={handleAcceptTerms}>agree & continue</Button>
        </div>
      </div>
    </>
  ) : null;
};

export default DisclaimerModal;
