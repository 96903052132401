import classNames from 'classnames';
import styles from './Button.module.scss';

const Button = ({ children, onClick, className, disabled, type, style }) => {
  return (
    <button
      className={classNames(styles.button, className)}
      style={style}
      onClick={onClick}
      disabled={disabled}
      type={type}>
      {children}
    </button>
  );
};

export default Button;
