import { ethInstance } from 'evm-chain-scripts';
import AmaluEligibility from '../../contracts/AmaluEligibility.json';

export const WhitelistEligibility = async (
  chainId,
  address,
  eligibilityIndex,
  eligibilityAddress
) => {
  let addressMerkleProof = [];
  let isEligibleToMint = false;
  try {
    const eligibilityContract = await ethInstance.getReadContractByAddress(
      AmaluEligibility,
      eligibilityAddress,
      chainId
    );

    isEligibleToMint = await eligibilityContract.isEligible(
      eligibilityIndex,
      address,
      addressMerkleProof
    );
  } catch (error) {
    console.error(
      error.code ?? error,
      `Eligibility check error occurred: please check account/network you're connected to.`
    );
    throw Error(`You're not eligible, check account and try again`);
  }
  return { addressMerkleProof, isEligibleToMint };
};

export const WhitelistWithdrawals = async (
  chainId,
  address,
  eligibilityIndex,
  eligibilityAddress
) => {
  let withdrawals = {};
  try {
    const eligibilityContract = await ethInstance.getReadContractByAddress(
      AmaluEligibility,
      eligibilityAddress,
      chainId
    );
    const [, maxWithdrawals, numWithdrawals] = await eligibilityContract.getGate(eligibilityIndex);
    withdrawals = {
      timesWithdrawn: parseInt(numWithdrawals),
      maxWithdrawals: parseInt(maxWithdrawals)
    };
  } catch (error) {
    console.error(
      error.code ?? error,
      `Withdrawals check error occurred: please check account/network you're connected to.`
    );
    throw Error(`You're not eligible, check account and try again`);
  }
  return withdrawals;
};
