export const STATUSES = {
  AWAITING: 'awaiting',
  OPEN: 'open',
  CLOSED: 'closed',
  DRAFT: 'draft',
  PREVOTE: 'prevote',
  RANKING: 'ranking'
};

const checkCommonStatuses = (startDate, endDate, ts) => {
  if (startDate <= ts && endDate > ts) return STATUSES.OPEN;
  if (endDate <= ts) return STATUSES.CLOSED;
};

export const getTaskStatus = (startDate, endDate, upDownEndDate) => {
  const ts = Date.now();
  if (startDate > ts) return STATUSES.AWAITING;
  if (upDownEndDate && upDownEndDate >= ts && endDate < ts) return STATUSES.RANKING;
  const status = checkCommonStatuses(startDate, endDate, ts);
  if (status) return status;
};

export const getProposalStatus = (startDate, endDate, isDraft, isPrevote) => {
  const ts = Date.now();
  if (isDraft) return STATUSES.DRAFT;
  const status = checkCommonStatuses(startDate, endDate, ts);
  if (status) return status;
  if (startDate > ts && !isDraft && !isPrevote) return STATUSES.AWAITING;
  if (isPrevote && !isDraft && startDate > ts) return STATUSES.PREVOTE;
};
