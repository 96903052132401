import { dedicatedWalletConnector } from '@magiclabs/wagmi-connector';

export const magicWallet = () => ({
  id: 'magic',
  name: 'Magic',
  iconUrl: 'https://svgshare.com/i/pXA.svg',
  createConnector: (walletDetails) => {
    const connectorFn = dedicatedWalletConnector({
      options: {
        apiKey: 'pk_live_399639BF8ACB831D',
        isDarkMode: true,
        enableEmailLogin: true,
        oauthOptions: {
          providers: ['google']
        }
      }
    });

    const switchChain = ({ chainId }) => {
      console.log('switch to chainId', chainId);
    };

    return (cfg) => ({
      ...connectorFn(cfg),
      switchChain,
      ...walletDetails
    });
  }
});
