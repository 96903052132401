import axios from 'axios';
import { MINT_API_URL } from 'helpers/utils';

export async function qrCodeEligibility(
  _chainId,
  address,
  _eligibilityIndex,
  _eligibilityAddress,
  _seriesId,
  groupKey,
  code
) {
  const { data: series } = await axios.get(
    `${MINT_API_URL}/minting/${groupKey}/check/${address}?code=${code}`
  );
  if (series.success === false) {
    return { addressMerkleProof: [], isEligibleToMint: false };
  }
  return { addressMerkleProof: [], isEligibleToMint: true };
}

export async function qrCodeWithdrawals(...args) {
  const isCodeAvailable = args.at(-1);
  return {
    timesWithdrawn: 0,
    maxWithdrawals: isCodeAvailable ? 1 : 0
  };
}
