import close from 'assets/close.svg';
import VotingIdentity from 'contracts/VotingIdentity2.json';
import { useGlobalState } from 'globalStateStore';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { REALM_CONTRACT_MAP } from 'pages/myLondonVoice/realm-contract-config';
import { useEffect, useState } from 'react';
import { useAccount, useReadContracts } from 'wagmi';
import NavItem from '../NavItem';
import styles from './NavBar.module.scss';

const MENU_LIST = [
  { text: 'Home', href: '/' },
  {
    text: 'How it works',
    href: 'https://factorydao.notion.site/London-VOICE-app-e4abd8314d0e4dc8977c6fd45b9cfa89'
  },
  { text: 'Contact us', href: 'https://t.me/factdao' }
];

const contractsConfig = Object.values(REALM_CONTRACT_MAP).map((realm) => {
  return {
    address: realm.contractAddress,
    chainId: realm.chainId,
    functionName: 'balanceOf',
    abi: VotingIdentity.abi
  };
});

const NavBar = ({ onCloseClick }) => {
  const pathname = usePathname();
  const { address } = useAccount();
  const [menuItems, setMenuItems] = useState(MENU_LIST);
  const [mintingStatus] = useGlobalState('mintingStatus');

  const router = useRouter();

  const { data: identities, refetch: refetchIdentities } = useReadContracts({
    contracts: contractsConfig.map((contract) => {
      return { ...contract, args: [address] };
    })
  });

  useEffect(() => {
    refetchIdentities();
  }, [router.pathname, mintingStatus]);

  useEffect(() => {
    const hasSomeId =
      identities?.length && identities?.some((contract) => contract.result > 0 && !contract.error);

    let newMenu = MENU_LIST;

    if (hasSomeId && !menuItems.find((item) => item.href === '/my-london-voice')) {
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index)
      ];

      newMenu = [
        ...insert(menuItems, 1, {
          text: 'My London Voice',
          href: '/my-london-voice'
        })
      ];
    } else {
      newMenu = menuItems.filter((item) => item.href !== '/my-london-voice');
    }
    setMenuItems(newMenu);
  }, [identities]);

  return (
    <nav className={`${styles.container} ${styles.mobileContainer}`}>
      <div className={styles.content}>
        <div className={styles.menuItems}>
          {menuItems.map((menu, id) => {
            const isHome = pathname === '/' && pathname === menu.href;
            return (
              <NavItem
                isActive={isHome || (menu.href !== '/' && pathname.includes(menu.href))}
                key={id}
                {...menu}
                onClick={onCloseClick}
              />
            );
          })}
        </div>
        <div className={styles.text} style={{ paddingBottom: 30 }}>
          <span>
            To join the community and learn more about decentralisation and democratic AI:{' '}
            <Link href="https://www.factorydao.org" alt="FactoryDAO">
              FactoryDAO
            </Link>
          </span>
          <br />
          <br />
          <span>
            “London Voice” is a democratic AI experiment facilitated by{' '}
            <Link href="https://www.factorylabs.org" alt="FactoryLabs">
              FactoryLabs
            </Link>
          </span>
        </div>
      </div>
      <Image src={close} alt="close icon" onClick={onCloseClick} className={styles.closeImg} />
    </nav>
  );
};

export default NavBar;
