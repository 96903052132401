import axios from 'axios';
import { VoteTabs } from 'components/Activities/helper';
import {
  getCombinedVoterIds,
  getContractsByNFTAddresses,
  getFromBackend,
  getNftMetadata,
  shortenWalletAddress
} from 'helpers/influence';
import {
  API_URL,
  formatProposal,
  generateRandomBackground,
  shorten,
  validateAcceptableChains
} from './utils';

export const getIdentities = async (votes, strategy, isOpenVote, address, currentNetwork) => {
  try {
    if (!address) return {};
    const {
      data: { identities: myIdentities }
    } = await axios.get(
      `${API_URL}/api/identities/${currentNetwork}/${
        strategy.params?.collectionName || strategy.params?.nftName || 'nft'
      }/${address}`
    );
    const identities = {};
    if (!myIdentities.length) return identities;
    for (let id of myIdentities) {
      const stringifiedId = id.toString();

      const idVoted = Object.values(votes).find((vote) => {
        if (Array.isArray(vote.msg.payload.voterId)) {
          return (
            vote.msg.payload.voterId.includes(stringifiedId) &&
            vote.msg.payload.network == currentNetwork
          );
        }
        return (
          vote.msg.payload.voterId == stringifiedId && vote.msg.payload.network == currentNetwork
        );
      });

      if (idVoted || isOpenVote) identities[stringifiedId] = stringifiedId;
    }
    return identities;
  } catch (err) {
    console.error(err);
  }
};

export const checkIfIdentityOnLists = (currentNetwork, payload, itemToCompare) => {
  let isVotePermission = true;
  if (Object.keys(payload).length) {
    const { whitelist, blacklist } = payload;
    if (
      whitelist &&
      blacklist &&
      whitelist[currentNetwork]?.length &&
      blacklist[currentNetwork]?.length
    ) {
      const whiteListMinusBlackList = whitelist[currentNetwork].filter(
        (r) => !blacklist[currentNetwork].includes(r)
      );
      const whitelisted = whiteListMinusBlackList.find(
        (item) => item?.toLowerCase() === itemToCompare?.toLowerCase()
      );
      isVotePermission = whitelisted;
    } else {
      if (blacklist && blacklist[currentNetwork]?.length) {
        const blacklisted = blacklist[currentNetwork].find(
          (item) => item?.toLowerCase() === itemToCompare?.toLowerCase()
        );
        isVotePermission = !blacklisted;
      } else if (whitelist && whitelist[currentNetwork]?.length) {
        const whitelisted = whitelist[currentNetwork].find(
          (item) => item?.toLowerCase() === itemToCompare?.toLowerCase()
        );
        isVotePermission = whitelisted;
      }
    }
    return !!isVotePermission;
  }
};

export const getIdentitiesDropdownOptions = (identities, strategy, shortLabel, className) => {
  if (!identities) return [];
  return Object.keys(identities).map((identity) => ({
    value: identity,
    label: `${strategy?.params?.nftName ?? 'FVT'} ${
      shortLabel ? shortenWalletAddress(identity) : identity
    }`,
    className
  }));
};

export const getMultiChainIdentitiesDropdownOptions = (identities, className) => {
  if (!identities) return [];
  return Object.keys(identities).map((identity) => ({
    value: identity, //contract address
    label: identities[identity][0].symbol,
    className
  }));
};

export const getMyInfluenceProps = (tab, isProposalVisible, totalResults, strategy) => {
  const commonProps = {
    isCombinedChains: !!(strategy?.key === 'combined-chains'),
    isOs1155: !!(strategy?.key === 'os-1155'),
    nftName: strategy?.params?.nftName,
    symbol: strategy?.params?.symbol,
    nftAddresses: strategy?.params?.nftAddresses,
    hideBalanceAndChart: strategy?.params?.hideBalanceAndChart,
    isSum: strategy?.isSum
  };
  if (tab === VoteTabs.results && isProposalVisible) {
    return {
      ...commonProps,
      firstData: totalResults.votes,
      secondData: totalResults.voteCredits,
      thirdData: totalResults.voter,
      isMyVotesTab: false
    };
  }
  return {};
};

export const getVoteComponentProps = (address, strategy, setUnsortedChoices, setTokensLeft) => {
  return {
    isConnected: !!address.length,
    symbol: shorten(strategy?.params?.symbol, 'symbol'),
    negativeVote: strategy.negativeVote,
    setUnsortedChoices,
    setTokensLeft
  };
};

const calcQvPowerForVoter = (value) => {
  return value * value;
};

export const calcVotesAndOpinions = (votes, choices, isBinary) => {
  let opinions = [];
  let totalVotesCredits = 0;
  let totalVotes = 0;
  let totalVoter = Object.keys(votes).length;
  const calcPower = isBinary ? (choiceValue) => choiceValue : calcQvPowerForVoter;

  if (!choices.length) return { opinions, totalVotesCredits };
  if (Object.keys(votes).length > 0) {
    // generate opinions array
    for (let vote of Object.values(votes)) {
      const time = new Date(parseInt(vote.msg.timestamp));
      const payload = vote.msg.payload;

      for (let choice of payload.choice) {
        totalVotesCredits += Math.round(calcPower(choice.value));
        totalVotes += choice.value;
        if (!choice.opinion?.content?.length) continue;
        const res = choices?.find((choiceItem) => choiceItem.choice.id === choice.cid);
        opinions.push({
          id: choice.cid,
          tag: res.choice.tag,
          choiceValue: res.choice.value,
          address: vote.address,
          opinion: choice.opinion.content,
          value: choice.isNegative ? choice.value * -1 : choice.value,
          time: time,
          voterId: payload.voterId,
          avatar: generateRandomBackground(vote.address),
          networkId: payload.network
        });
      }
    }
    opinions = opinions.sort((a, b) => b.time.getTime() - a.time.getTime());
  }
  return { opinions, totalVotesCredits, totalVotes, totalVoter };
};

export const getMultiChainVoterNftIdentities = async (
  votes,
  strategy,
  address,
  acceptableChains
) => {
  const resultOfIds = {};
  const userAlreadyVoted = Object.keys(votes).includes(address);

  const validateNftAddresses = validateAcceptableChains(
    acceptableChains,
    strategy.params?.nftAddresses
  );

  try {
    if (userAlreadyVoted) {
      let contracts = getContractsByNFTAddresses(validateNftAddresses);

      const contractsResult = await Promise.all(contracts);
      const groupedNFTs = {};

      for (const contract of contractsResult) {
        const symbol = await contract.symbol();
        const nftIds = votes[address].msg.payload.contractsVoterIds[contract.address];

        if (nftIds && nftIds.length > 0) {
          groupedNFTs[contract.address] = nftIds.map((id) => {
            return { nftId: parseInt(id), symbol: symbol };
          });
        }
      }
      return groupedNFTs;
    }

    const voterIds = await getCombinedVoterIds(
      address,
      validateNftAddresses,
      strategy.params?.values
    ); //ids to vote

    const allVotes = Object.values(votes).filter(
      (vote) => vote.address !== address && vote.voterNfts
    );
    if (allVotes.length === 0) return voterIds;

    for (const vote of allVotes) {
      for (const key in voterIds) {
        const idsVoted = vote.voterNfts[key];
        let idsToVote = [];

        if (voterIds[key]) idsToVote = voterIds[key];
        else continue;

        const freeIdsToVote = idsToVote.filter((vote) => {
          return idsVoted ? idsVoted.indexOf(vote.nftId) == -1 : true;
        });

        if (freeIdsToVote.length > 0) resultOfIds[key] = freeIdsToVote;
      }
    }
  } catch (err) {
    console.log('No identities found');
  }

  return resultOfIds;
};

export const fetchNftImage = async (tokenURI) => {
  if (!tokenURI) return;

  const metadata = await getNftMetadata(tokenURI);

  if (!metadata) {
    return { mediaUrl: null, isVideo: false };
  }

  if (metadata.animation_url?.startsWith('ipfs')) {
    return {
      mediaUrl: 'https://factorydao.infura-ipfs.io/ipfs/' + metadata.image.replace('ipfs://', ''),
      isVideo: true
    };
  } else if (metadata.animation_url) {
    return { mediaUrl: metadata.image, isVideo: true };
  }
  if (metadata.image.startsWith('ipfs')) {
    return {
      mediaUrl: 'https://factorydao.infura-ipfs.io/ipfs/' + metadata.image.replace('ipfs://', ''),
      isVideo: false
    };
  } else {
    return { mediaUrl: metadata.image, isVideo: false };
  }
};

export const getProposalData = async (id) => {
  try {
    let proposal = await getFromBackend(id);
    proposal.ipfsHash = id;
    proposal = formatProposal(proposal);

    return proposal;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const sortVotesList = (proposalVotes) => {
  const allVotesNew = [];
  for (const [voteKey, voteData] of Object.entries(proposalVotes)) {
    let calcScore = 0;
    for (let i = 0; i < voteData.msg.payload.choice.length; i++) {
      const value = Math.pow(voteData.msg.payload.choice[i].value, 2);
      calcScore += value;
    }

    if (calcScore > 0) allVotesNew.push([voteKey, { ...voteData, score: calcScore }]);
  }
  const sortedNewVotes = allVotesNew.sort((a, b) => b[1].score - a[1].score);
  return Object.fromEntries(sortedNewVotes);
};

export const fetchProposalVotesData = async (
  proposal,
  address,
  spaceKey,
  id,
  signWindowOpenRef,
  currentNetwork
) => {
  try {
    if (!proposal) return { votes: {}, isProposalVisible: false, isCombinedChainsVoting: false };
    const restrictedProposal = proposal?.msg?.payload?.nftUsersOnly;
    const isCombinedChainsVoting = proposal.strategy.params.type === 'sim-multi';

    if (restrictedProposal && !address)
      return { proposal, votes: {}, myVotes: {}, results: {}, isProposalVisible: false };

    const requestParams = {
      address
    };

    let getProposalEndpoint = `${API_URL}/api/spaces/${spaceKey}/proposal/${id}`;

    if (restrictedProposal) {
      getProposalEndpoint += '/restricted';

      if (!signWindowOpenRef.current) {
        signWindowOpenRef.current = true;
        signWindowOpenRef.current = false;
      }
    }

    if (!isCombinedChainsVoting) requestParams.network = currentNetwork;

    const { data: votesData } = await axios.get(getProposalEndpoint, {
      params: requestParams
    });

    const { votes, isProposalVisible } = votesData;

    return { votes, isProposalVisible, isCombinedChainsVoting };
  } catch (e) {
    console.log('Error during loading proposal votes data', e);
    return { votes: {}, isProposalVisible: false, isCombinedChainsVoting: false };
  }
};

export const calculateResults = (
  votes,
  currentNetwork,
  proposal,
  myAddress,
  isCombinedChainsVoting = false
) => {
  const votesArray = Object.values(votes);
  const myInfluenceResultPerIdentity = {};
  const influenceResult = [];
  const myInfluenceResult = [];

  if (
    ['erc20-balance-of', 'erc20-balance-of-qv'].includes(proposal?.strategy?.key) ||
    !proposal?.strategy
  ) {
    return {
      influenceResult,
      myInfluenceResult,
      myInfluenceResultPerIdentity
    };
  }

  for (let i = 0; i < votesArray.length; i++) {
    if (
      isCombinedChainsVoting ||
      parseInt(votesArray[i].msg.payload.network) === parseInt(currentNetwork)
    ) {
      const choicesArr = [];
      for (let j = 0; j < proposal.msg.payload.choices.length; j++) {
        const choiceElem = votesArray[i].msg.payload.choice.find((choice) => choice.cid === j + 1);
        if (!choiceElem) {
          choicesArr.push(0);
          continue;
        }
        const value = choiceElem.value ?? 0;
        choicesArr.push(choiceElem.isNegative ? value * -1 : value);
      }
      myInfluenceResultPerIdentity[votesArray[i].msg.payload.voterId] = choicesArr;
    }
  }

  for (let i = 0; i < proposal.msg.payload.choices.length; i++) {
    const tempInfluenceRes = [];
    const tempMyInfluenceRes = [];
    for (const vote of votesArray) {
      const choiceElem = vote.msg.payload.choice.find((choice) => choice.cid === i + 1);
      if (!choiceElem) continue;
      const value = choiceElem.value ?? 0;
      const result = choiceElem.isNegative ? value * -1 : value;
      if (vote.address === myAddress) tempMyInfluenceRes.push(result);
      tempInfluenceRes.push(result);
    }
    influenceResult.push(tempInfluenceRes.reduce((a, b) => a + b, 0));
    myInfluenceResult.push(tempMyInfluenceRes.reduce((a, b) => a + b, 0));
  }

  return {
    influenceResult,
    myInfluenceResult,
    myInfluenceResultPerIdentity
  };
};
