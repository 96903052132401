import classNames from 'classnames';
import Link from 'next/link';
import styles from './NavItem.module.scss';

const NavItem = ({ text, href, isActive, onClick }) => (
  <Link
    className={classNames(styles.item, isActive ? styles.active : '')}
    href={href}
    onClick={onClick}
    target={href.includes('https') ? '_blank' : '_self'}>
    {text}
  </Link>
);

export default NavItem;
