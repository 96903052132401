import { getDefaultConfig, getDefaultWallets } from '@rainbow-me/rainbowkit';
import {
  avalanche,
  avalancheFuji,
  mainnet,
  optimism,
  optimismSepolia,
  polygon
} from 'wagmi/chains';
import { magicWallet } from './magicConnectors/magicLoginConnector';

export const chains = [mainnet, polygon, avalanche, avalancheFuji, optimism, optimismSepolia];

export const wagmiConfig = getDefaultConfig({
  appName: 'LondonVoice',
  projectId: '9b7e0ed6297b48a2b19e4f44d10700b8',
  chains,
  wallets: [
    {
      groupName: 'Recommended',
      wallets: [magicWallet]
    },
    ...getDefaultWallets({
      chains,
      appName: 'LondonVoice',
      projectId: '9b7e0ed6297b48a2b19e4f44d10700b8'
    }).wallets
  ]
});
