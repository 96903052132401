export const getWithdrawals = async (
  eligibilityContract,
  eligibilityIndex,
  address,
  resultIndex
) => {
  const [timesWithdrawn, result] = await Promise.all([
    eligibilityContract.timesWithdrawn(eligibilityIndex, address),
    eligibilityContract.getGate(eligibilityIndex)
  ]);
  const withdrawals = {
    timesWithdrawn: parseInt(timesWithdrawn),
    maxWithdrawals: parseInt(result[resultIndex])
  };

  return withdrawals;
};
