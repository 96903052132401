import { WalletButton } from '@rainbow-me/rainbowkit';
import close from 'assets/close.svg';
import classnames from 'classnames';
import Image from 'next/image';
import { useEffect } from 'react';
import { useAccount, useConnect, useConnectors } from 'wagmi';
import Button from '../Button';
import styles from './ConnectorsModal.module.scss';

const ConnectorsModal = ({ showConnectorsModalDialog, setConnectorsModalDialog }) => {
  const { connect } = useConnect();
  const { isConnected } = useAccount();

  const connectors = useConnectors();

  const onCloseClick = () => {
    setConnectorsModalDialog(false);
  };

  useEffect(() => {
    if (isConnected) {
      setConnectorsModalDialog(false);
    }
  }, [isConnected]);

  const magicLinkConnector = connectors.find((connector) => connector.id === 'magic');

  return showConnectorsModalDialog ? (
    <>
      <div className={styles.backdrop} />
      <div className={classnames(styles.container, styles.modal)}>
        <Image src={close} alt="close icon" onClick={onCloseClick} className={styles.closeIcon} />
        <div className={styles.modalBody}>
          <Button
            className={styles.magicLinkConnector}
            onClick={() => connect({ connector: magicLinkConnector })}>
            sign up with email
          </Button>
          <div className={styles.divider}>OR</div>
          <div className={styles.otherWalletContainer}>
            <WalletButton wallet="metamask" />
            <WalletButton wallet="walletConnect" />
            <WalletButton wallet="rainbow" />
            <WalletButton wallet="coinbase" />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default ConnectorsModal;
