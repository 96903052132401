import logo from 'assets/logo.svg';
import personImg from 'assets/person.svg';
import classNames from 'classnames';
import { MINTING_STATUSES } from 'components/Activities/ClaimVoice/helpers';
import SignInButton from 'components/Siwe/Siwe';
import { useGlobalState } from 'globalStateStore';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import HamburgerIcon from './HamburgerIcon';
import styles from './Header.module.scss';
import NavBar from './NavBar';

const Account = () => {
  const [space] = useGlobalState('space');
  const [currentNetwork, setCurrentNetwork] = useGlobalState('currentNetwork');
  const [isMember] = useGlobalState('isMember');
  const [showChainsDropdown] = useGlobalState('showChainsDropdown');
  const [supportedChains] = useGlobalState('supportedChains');
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const [mintingStatus] = useGlobalState('mintingStatus');

  const pathname = usePathname();
  const { isConnected } = useAccount();
  const spaceLoaded = space && Object.entries(space).length > 0;

  const toggleMobileMenu = () => setIsMobileMenuOpened(!isMobileMenuOpened);

  useEffect(() => {
    if (spaceLoaded && !space.isNamespace) {
      const chains = supportedChains.length ? supportedChains : space?.networks;

      if (!showChainsDropdown) setCurrentNetwork(parseInt(chains[0]));
    }
  }, [space, currentNetwork, spaceLoaded, supportedChains, showChainsDropdown]);

  const location = typeof window !== 'undefined' && window.location;

  useEffect(() => {
    onCloseMobileMenu();
  }, [location]);

  const onCloseMobileMenu = () => {
    setIsMobileMenuOpened(false);
  };

  const chainDropdownCondition = showChainsDropdown && spaceLoaded;
  const CustomSignInCondition = isMember && isConnected;
  const isBigIcon =
    pathname === '/' ||
    (pathname === '/activities/claimVoice' && mintingStatus !== MINTING_STATUSES.DONE);

  return (
    <>
      {isMobileMenuOpened && <div className={styles.backdrop} />}
      {!isBigIcon && <div className={styles.strongLine} />}
      <div className={isMobileMenuOpened ? styles.mobileMenu : ''}>
        {isMobileMenuOpened && <NavBar onCloseClick={onCloseMobileMenu} />}
      </div>
      <div className={classNames(styles.containerHome, !isBigIcon ? styles.container : '')}>
        <nav className={classNames(styles.nav, isBigIcon ? styles.homeNav : '')}>
          <div className={classNames(styles.navLeft, isBigIcon ? styles.homeLeftNav : '')}>
            <div className={styles.hamburgerIcon}>
              <HamburgerIcon onClick={toggleMobileMenu} />
            </div>
            <Link
              className={styles.link}
              href={{
                pathname: `/`
              }}>
              <Image
                className={styles.logo}
                width={isBigIcon ? 231 : 138}
                height={isBigIcon ? 69 : 41}
                src={logo}
                alt="london voice logo"
              />
            </Link>
          </div>
          <div className={styles.desktopMenu}>
            <NavBar />
          </div>
          <div
            className={classNames(
              styles.rightNav,
              chainDropdownCondition || CustomSignInCondition
                ? isMobileMenuOpened
                  ? styles.isMobileOpened
                  : styles.hiddenOnMobile
                : null
            )}>
            <SignInButton
              walletElement={<Image src={personImg} width={17} height={19} alt="person image" />}
            />
          </div>
        </nav>
      </div>
    </>
  );
};

export default Account;
