import { RainbowKitProvider, darkTheme } from '@rainbow-me/rainbowkit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axios from 'axios';
import DisclaimerModal from 'components/Activities/DisclaimerModal';
import SEO from 'components/SEO';
import App from 'next/app';
import dynamic from 'next/dynamic';
import { Inter } from 'next/font/google';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { WagmiProvider } from 'wagmi';
import Header from '../src/components/Header';
import { GlobalStateProvider } from '../src/globalStateStore';
import { wagmiConfig } from '../src/helpers/wagmiConfig';
import '../src/styles.scss';

const inter = Inter({
  subsets: ['latin'],
  weight: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
  variable: '--font-inter'
});

const PageLoading = dynamic(() => import('components/Ui/PageLoading/PageLoading.component'));
const queryClient = new QueryClient();

const isServer = () => {
  return typeof window === 'undefined';
};

async function getMetatags(id) {
  const {
    data: {
      submission: { title, description, image }
    }
  } = await axios.get(`${process.env.API_URL}/api/submissionimages/${id}`);

  return { title, description, image };
}

const DefaultHead = () => (
  <Head>
    <title>London Voice</title>
    <meta
      name="description"
      content="Join the revolution. Use your voice. Organize without permission."
    />
    <meta
      httpEquiv="Content-Security-Policy"
      content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
    />
  </Head>
);

function MyApp({ Component, pageProps, seo }) {
  const [isBrowser, setIsBrowser] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  return (
    <>
      {seo ? (
        <SEO
          url={seo.url}
          title={seo.title}
          description={seo.description}
          image={`${process.env.API_URL}${seo.image}`}
        />
      ) : (
        <DefaultHead />
      )}
      <ToastContainer limit={2} />

      {isBrowser ? (
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <RainbowKitProvider theme={darkTheme()}>
              <main className={inter.className}>
                <DisclaimerModal />
                <GlobalStateProvider>
                  <div className="content">
                    <Header />
                    <div className="layout">
                      <div className="component">
                        <Component {...pageProps} />
                      </div>
                    </div>
                  </div>
                </GlobalStateProvider>
              </main>
            </RainbowKitProvider>
          </QueryClientProvider>
        </WagmiProvider>
      ) : (
        <PageLoading />
      )}
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const isServer = !!appContext.ctx.req;
  const additional = {};

  if (isServer && appContext.ctx.req.url.includes('/task/') && appContext.router.query.id) {
    additional.seo = await getMetatags(appContext.router.query.id);
  }
  return { ...appProps, ...additional };
};

export default MyApp;

if (!isServer()) {
  const getCookieValue = (name) =>
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

  axios.interceptors.request.use((config) => {
    const address = getCookieValue(`walletAddress`);
    const sessionToken = getCookieValue(`session_${address}`);
    const anotherContentType = config.headers['Content-Type'];

    config.headers = {
      'Content-Type': anotherContentType ?? 'application/json',
      [`session_${address}`]: sessionToken,
      credentials: 'include'
    };

    return config;
  });
}

// axios.defaults.baseURL = process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8080';
// axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (request) => {
    return request;
  },
  (error) => {
    if (axios.isCancel(error)) throw Error(error);
    const status = error.response?.status;
    const ethersError = error.response?.data?.error?.code;
    let errorData =
      error.response?.data?.error ??
      error.response?.error ??
      error.response?.data?.error_description ??
      error.message;
    if (ethersError) {
      errorData = `${errorData.code}, ${errorData.argument}, ${errorData.value}`;
    }
    let msg;
    switch (status) {
      case 401:
        msg = '401 Unauthorized';
        break;
      case 400:
        msg = 'Bad request';
        break;
      case 500:
        msg = 'Technical error';
        break;
      default:
        msg = `'Unexpected error, status: ${status}:`;
        break;
    }
    if (console && console.error) console.error(msg, errorData);

    if (errorData) {
      toast.clearWaitingQueue();
      toast.error(
        <p className="toast__centered">
          Error occured.
          <br />
          Check console for more details
        </p>,
        { position: 'top-center' }
      );
    }
    throw Error(errorData);
  }
);
