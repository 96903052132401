import axios from 'axios';
import { ethInstance } from 'evm-chain-scripts';
import { MINT_API_URL } from 'helpers/utils';
import MerkleEligibility from '../../contracts/MerkleEligibility.json';
import { getWithdrawals } from './helper';

export const MerkleEligibilityCheck = async (
  chainId,
  address,
  eligibilityIndex,
  eligibilityAddress,
  seriesId,
  key
) => {
  let addressMerkleProof = [];
  let isEligibleToMint = false;
  try {
    const { data: proof } = await axios.get(
      `${MINT_API_URL}/minting/proof/${key}/${seriesId}/${address}`
    );
    addressMerkleProof = proof;
    if (proof) {
      const eligibilityContract = await ethInstance.getReadContractByAddress(
        MerkleEligibility,
        eligibilityAddress,
        chainId
      );
      isEligibleToMint = await eligibilityContract.isEligible(eligibilityIndex, address, proof);
    } else {
      isEligibleToMint = false;
    }
  } catch (error) {
    console.error(
      error.code ?? error,
      `Eligibility check error occurred: please check account/network you're connected to.`
    );
    throw Error(`You're not eligible, check account and try again`);
  }
  return { addressMerkleProof, isEligibleToMint };
};

export const MerkleWithdrawalsCheck = async (
  chainId,
  address,
  eligibilityIndex,
  eligibilityAddress
) => {
  let withdrawals = {};
  try {
    const eligibilityContract = await ethInstance.getReadContractByAddress(
      MerkleEligibility,
      eligibilityAddress,
      chainId
    );

    withdrawals = await getWithdrawals(eligibilityContract, eligibilityIndex, address, 1);
  } catch (error) {
    console.error(
      error.code ?? error,
      `Withdrawals check error occurred: please check account/network you're connected to.`
    );
    throw Error(`You're not eligible, check account and try again`);
  }
  return withdrawals;
};
