import axios from 'axios';
import { getTaskStatus, STATUSES } from './statuses';
import { API_URL, getParsedUserId } from './utils';

export const getTaskData = async (hash, spaceKey, address) => {
  try {
    const { data } = await axios.get(`${API_URL}/api/tasks/${spaceKey}/${hash}`);

    const currentTimestamp = Date.now();
    const { startDate, endDate, upDownEndDate } = data.question;
    const status = getTaskStatus(startDate, endDate, upDownEndDate);
    const isOpenTask = status === STATUSES.OPEN;

    const questionData = { ...data.question, isOpenTask, status };

    const submissionsData = data.submissions.map((submission) => {
      const userData = getParsedUserId(submission.userId);
      const score =
        submission.upVotes && submission.downVotes
          ? submission.upVotes.length - submission.downVotes.length
          : 0;

      return {
        ...submission,
        networkId: userData.chainId,
        userId: userData.nftId,
        contractAddress: userData.contractAddress,
        isOs1155: userData.os1155,
        score
      };
    });

    const contributorsData = Object.keys(data.contributors).map((contrKey) => {
      const userData = getParsedUserId(contrKey);
      return {
        ...data.contributors[contrKey],
        networkId: userData.chainId,
        userId: userData.nftId,
        contractAddress: userData.contractAddress,
        isOs1155: userData.os1155
      };
    });

    let identitiesForRealmByUser = {};
    let mySubmissionsData = [];
    if (address) {
      mySubmissionsData = submissionsData.filter(
        (sub) => sub.voterAddress.toLowerCase() === address.toLowerCase()
      );
      const identitiesData = await axios.get(
        `${API_URL}/api/tasks/${spaceKey}/${hash}/check/${address}`
      );
      identitiesForRealmByUser = identitiesData?.data || {};
    }

    const hasIdentities = !!Object.keys(identitiesForRealmByUser).length;
    const isEligible = startDate < currentTimestamp && endDate > currentTimestamp && hasIdentities;
    return {
      submissionsData,
      mySubmissionsData,
      contributorsData,
      identitiesForRealmByUser,
      isEligible,
      questionData
    };
  } catch (error) {
    console.log('GET_SUBMISSIONS_QUESTION_FAILURE', error);
    return {
      submissionsData: [],
      mySubmissionsData: [],
      contributorsData: [],
      identitiesForRealmByUser: {},
      isEligible: false,
      questionData: {}
    };
  }
};
